import type { ConfiguratorValueV1 } from '../neon/types'

function getWordPrice(word: string, letterPrice: number) {
	return word.replace(/\s+/g, '').length * letterPrice
}

export function getConfiguratorPrice(
	value: ConfiguratorValueV1,
	fontCharPrice: number,
	canvasSizePriceKoef: number
) {
	let letters = 0
	const icons = 0
	let paths = 0

	for (const part of value.parts) {
		if (part.providers && 'text' in part.providers) {
			const pricePerLetter = fontCharPrice
			const text = value.inputValues[part.providers.text]
			letters += getWordPrice(text, pricePerLetter)
		}
		if ('icon' in part) {
			// const iconPrice = context.icons[part.icon].localesByLocale?.price || 100
			// icons += iconPrice
		} else if ('path' in part) {
			paths += part.pathPrice
		}
	}

	const unscaledTotal = letters + icons + paths
	const preTotal = canvasSizePriceKoef * unscaledTotal
	const total = preTotal < 1900 ? 1900 : Math.round(preTotal)

	return { unscaledTotal, total, canvasSizePriceKoef, letters, icons, paths }
}
