import { hexToHsluv, hsluvToHex, hsluvToRgb } from 'hsluv'
import Image from 'next/image'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useShoppingCart } from 'use-shopping-cart'
import type { Product } from 'use-shopping-cart/core'
import { v4 as uuidv4 } from 'uuid'
import { Button } from '../../components/Button'
import { CZ_CHARS, Dict, fixMissingChars } from '../../utils/fixMissingCharacters'
import { ShoppingContext } from '../../utils/ShoppingContext'
import { ConfiguratorInfoContextValue, useConfiguratorInfoContext } from '../contember/transformers'
import type {
	CanvasSizeId,
	ConfiguratorInputType,
	ConfiguratorPartEditability,
	ConfiguratorPreset,
	ConfiguratorValuePart,
	ConfiguratorValueV1,
	InputId,
} from '../types'
import { backgrounds } from './backgrounds'
import s from './Configurator.module.sass'
import { normalizeConfiguratorValue } from './normalizeConfiguratorValue'
import { RadioPicker } from './RadioPicker'
import type { ConfiguratorHandler } from './useConfiguratorHandler'
import { useConfiguratorPriceInfo } from './useConfiguratorPriceInfo'

export type ConfiguratorProps = {
	preset: ConfiguratorPreset
	handler: ConfiguratorHandler
}

export const createStyle = (color: string) => {
	const ref = hexToHsluv(color)
	const fill = hsluvToHex([ref[0], ref[1], ref[2] + Math.min(10, (100 - ref[2]) / 4)])
	return {
		color: `${fill}`,
		textShadow: `0 0 .3em rgba(${hsluvToRgb(ref)
			.map((x) => x * 255)
			.join(',')}, .6), 0 0 .1em rgba(${hsluvToRgb(ref)
			.map((x) => x * 255)
			.join(',')}, .4)`,
	}
}

function ConfiguratorPartSvgRenderer<Index extends number>(props: {
	part: ConfiguratorValuePart
	value: ConfiguratorValueV1
	index: Index
	refresh: number
	offsets: { width: number; offsets: ({ x: number; y: number } | null)[] }
	onBounds: (bounds: DOMRect | null, index: Index) => void
}) {
	const { part, onBounds, index, refresh } = props

	const partId = part.id

	const inputValues = {
		text: props.value.inputValues[`text/${partId}`],
		lightMaterial: props.value.inputValues[`lightMaterial/${partId}`],
		font: props.value.inputValues[`font/${partId}`],
	}

	const [el, setEl] = useState<SVGGElement | null>(null)

	const info = useConfiguratorInfoContext()

	const fontId = props.value.font

	const fontInfo = info.fonts[fontId]

	useEffect(() => {
		let t: null | NodeJS.Timeout = null
		let i = 6

		const next = () => {
			onBounds(el ? el.getBBox() : null, index)
			t = setTimeout(() => {
				if (i-- > 0) {
					next()
				}
			}, 200 * (6 - i))
		}

		next()

		return () => {
			if (t) {
				clearTimeout(t)
			}
			i = 0
		}
	}, [el, onBounds, index, refresh, fontId, inputValues.text])

	const lightMaterial = inputValues.lightMaterial || props.value.lightMaterial

	const partColor = info.lightMaterials[lightMaterial].colorHex

	const fontScale = 100 / (fontInfo?.baseFontSize || 100)

	const filter = `drop-shadow(0px 0px 1px ${partColor})`

	const style = {
		color: partColor,
		fontSize: 12,
		filter,
		stroke: partColor,
	}

	const currentOffset = props.offsets.offsets[props.index] ?? null
	const transform = currentOffset ? `translate(${currentOffset.x}, ${currentOffset.y})` : undefined

	const centerProps = {
		x: '50%',
		y: '50%',
	}

	const textProps = {
		dominantBaseline: 'middle',
		...centerProps,
		style: {
			fontFamily: `'${fontId}'`,
			filter,
			fontSize: `${1 * fontScale}em`,
			fill: fontInfo.toBeOutlined ? 'transparent' : partColor,
			stroke: fontInfo.toBeOutlined ? partColor : 'none',
			strokeWidth: fontInfo.toBeOutlined ? '.7px' : 'none',
		},
	}

	const charDictionary: Dict | false = React.useMemo(() => {
		// @TODO: načítat z info, až to bude v contemberu
		switch (fontId) {
			case '5d4af203-f2e1-4b04-a352-0d9a94995d93':
				return CZ_CHARS
		}
		return false
	}, [fontId])

	let content: React.ReactNode | null = null
	if ('text' in part) {
		content = <text {...textProps}>{fixMissingChars(inputValues.text, charDictionary)}</text>
	} else if ('icon' in part) {
		const iconPaths = info.icons[part.icon].paths?.split('\n')
		if (!iconPaths) {
			throw new Error(`Invalid icon ${part.icon}`)
		}
		content = iconPaths.map((path, index) => (
			<svg key={index} {...centerProps} cx="-100">
				<path
					style={{
						...style,
						stroke: partColor,
						fill: 'transparent',
						strokeWidth: '.08em',
					}}
					d={path}
					stroke="black"
				/>
			</svg>
		))
	} else if ('path' in part) {
		content = (
			<svg {...centerProps} cx="-100">
				<path
					style={{
						...style,
						stroke: style.color,
						fill: 'transparent',
						strokeWidth: '.08em',
					}}
					d={part.path}
					stroke="black"
				/>
			</svg>
		)
	} else {
		throw new Error('Invalid state')
	}

	return (
		<>
			<g transform={transform} ref={setEl}>
				{content}
			</g>
		</>
	)
}

export function Configurator(props: ConfiguratorProps) {
	const { preset } = props
	const handler = props.handler
	const [value, actions] = handler

	const { addItem } = useShoppingCart()
	const shoppingContext = React.useContext(ShoppingContext)

	const [bgIndex, setBgIndex] = useState(0)

	const bg = backgrounds[bgIndex % backgrounds.length]

	const info = useConfiguratorInfoContext()

	const price = useConfiguratorPriceInfo(value, info)

	//const canvasSizes = preset.canvasSizes.map((item) => info.canvasSizes[item])

	const getCanvasSizeLabel = (value: string) => {
		return (
			<span className={s.SizeLabel}>{info.canvasSizes[value].localesByLocale?.name ?? value}</span>
		)
	}

	const getFontLabel = (value: string) => {
		return <span className={s.FontLabel}>{info.fonts[value].name ?? value}</span>
	}

	const getBackgroundLabel = (value: string) => {
		return (
			<span className={s.BackgroundLabel}>
				{
					// eslint-disable-next-line @next/next/no-img-element
					<img src={backgrounds[parseInt(value)].src.src} alt="room" />
				}
			</span>
		)
	}

	const handleSubmit = () => {
		//Set product name
		let productNameReset = false
		let productName = 'Tvůj neon'
		Object.entries(value.inputValues).forEach((item) => {
			if (!productNameReset && item[0].includes('text')) {
				productName = `Tvůj neon: ${item[1]}`
				productNameReset = true
			}
		})

		//Set other product data
		const product: Product = {
			name: productName,
			id: uuidv4(), //random uuid
			price: price.total,
			currency: 'CZK',
			image: 'https://www.neonhort.cz/tvujneon.jpg',
		}
		addItem(product, {
			product_metadata: {
				configurator_data: JSON.stringify(normalizeConfiguratorValue(value)),
			},
		})
		shoppingContext?.cartOpenFunction(true) //open cart
	}

	return (
		<form className={s.Configurator}>
			{/* <Dump data={info} /> */}
			<div className={s.Layout}>
				<div className={s.Controls}>
					{preset.inputs?.map((input) => (
						<ConfiguratorPartEditor
							key={input.inputId}
							input={input}
							handler={handler}
							preset={preset}
							info={info}
						/>
					))}
					<RadioPicker
						title="Velikost:"
						value={value.canvasSize}
						options={props.preset.canvasSizes}
						onChange={(_name, value) => actions.setSize(value)}
						name="canvasSize"
						label={getCanvasSizeLabel}
					/>
					<RadioPicker
						title="Písmo:"
						value={value.font}
						options={props.preset.fonts}
						onChange={(_name, value) => actions.setFontFamily(value)}
						name="font"
						label={getFontLabel}
					/>
					<RadioPicker
						title="Pozadí:"
						value={String(bgIndex)}
						options={backgrounds.map((_, i) => String(i))}
						onChange={(_name, value) => setBgIndex(Number(value))}
						name="background"
						label={getBackgroundLabel}
					/>
					<div className={s.Actions}>
						<div className={s.Price}>Cena: {price.total} Kč</div>
						<Button onClick={handleSubmit}>Přidat do košíku</Button>
						<p className={s.Info}>U neonu na míru je doba doručení cca 3 týdny od objednání.</p>
					</div>
					{/* <div>
						<Dump data={value} />
					</div> */}
				</div>
				<div className={s.Main}>
					<div className={s.Ratio}>
						<div className={s.Background}>
							{backgrounds.map((bg, b) => (
								<span
									className={s.BackgroundImage}
									key={b}
									style={{ opacity: b === bgIndex ? 1 : 0 }}>
									<Image src={bg.src} layout="fill" alt="" />
								</span>
							))}
						</div>
						<div className={s.RatioIn}>
							<div className={s.Canvas} style={{ transform: bg.neonTransform }}>
								<div className={s.Center}>
									<div className={s.Preview}>
										<div className={s.PreviewParts}>
											<ConfiguratorSvgPreview value={value} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

function ConfiguratorSvgPreviewLayer(props: {
	layerId: string
	value: ConfiguratorValueV1
	onReady: (layerId: string, ready: boolean) => void
}) {
	const { value, onReady, layerId } = props
	const { parts } = value

	const [bounds, setBounds] = useState<{
		v: number
		bounds: Record<string, DOMRect | undefined>
		prevBounds: Record<string, DOMRect | undefined>
	}>({
		v: 1,
		bounds: {},
		prevBounds: {},
	})

	const serialized = JSON.stringify(value.parts)

	useEffect(() => {
		setBounds((old) => ({ v: old.v + 1, bounds: {}, prevBounds: old.bounds }))

		const timeout = setTimeout(() => {
			setBounds((old) => ({ ...old, v: old.v + 1 }))
		}, 100)

		return () => clearTimeout(timeout)
	}, [serialized])

	const ready = Object.values(bounds.bounds).filter((x) => Boolean(x)).length === parts.length

	useEffect(() => {
		onReady(layerId, ready)
	}, [ready, layerId, onReady])

	const currentBounds = ready ? bounds.bounds : bounds.prevBounds

	const offsets = useMemo(() => {
		const offsets: (null | { x: number; y: number })[] = []
		let width = 0
		const partSpace = 5
		const iconCenterOffset = 3

		for (const i in parts) {
			const part = parts[i]
			const partBounds = currentBounds[i]
			if (partBounds) {
				if ('text' in part) {
					offsets.push({ x: width, y: 0 })
				} else if ('icon' in part) {
					// TODO: dočasně se chová jako text
					offsets.push({ x: width, y: -(partBounds.height / 2 + iconCenterOffset) })
				} else {
					offsets.push({ x: width, y: -partBounds.height / 2 })
				}
				width += partBounds.width + partSpace
			} else {
				offsets.push(null)
			}
		}

		return { width, offsets }
	}, [parts, currentBounds])

	const handleBounds = useCallback(
		(bounds: DOMRect | null, index: number) =>
			setBounds((old) => {
				if (bounds === old.bounds[index]) {
					return old
				}
				const update = { ...old, bounds: { ...old.bounds } }
				if (bounds) {
					update.bounds[index] = bounds
				} else {
					delete update.bounds[index]
				}
				return update
			}),
		[]
	)

	return (
		<g transform={`translate(${-offsets.width / 2})`}>
			{parts.map((part, i) => (
				<ConfiguratorPartSvgRenderer
					key={i}
					index={i}
					part={part}
					value={value}
					refresh={bounds.v}
					onBounds={handleBounds}
					offsets={offsets}
				/>
			))}
		</g>
	)
}

export function ConfiguratorSvgPreview(props: { value: ConfiguratorValueV1 }) {
	const { value } = props
	const { parts } = value
	const info = useConfiguratorInfoContext()

	const [readyStates, setReadyStates] = useState<Record<string, boolean>>(() => {
		const layers: Record<string, boolean> = {}

		for (const part of parts) {
			layers[part.layerId] = false
		}

		return layers
	})

	const ready = !Object.values(readyStates).some((x) => x === false)

	const selectedCanvasSize = value.canvasSize as CanvasSizeId

	const layers: { layerId: string; value: ConfiguratorValueV1 }[] = useMemo(() => {
		const layers: Record<string, ConfiguratorValueV1['parts']> = {}

		for (const part of parts) {
			const layer = layers[part.layerId] ?? []

			layer.push(part)

			layers[part.layerId] = layer
		}

		return Object.entries(layers).map(([layerId, layer]) => ({
			layerId: layerId,
			value: { ...value, parts: layer },
		}))
	}, [parts, value])

	const readyHandler = useCallback(
		(layerId: string, ready: boolean) =>
			setReadyStates((old) => {
				if (old[layerId] !== ready) {
					return { ...old, [layerId]: ready }
				}
				return old
			}),
		[]
	)

	return (
		<div
			className={s.PreviewSvg}
			style={{
				transform: `scale(${info.canvasSizes[selectedCanvasSize].scale})`,
				opacity: ready ? 1 : 0,
			}}>
			<svg viewBox="0 0 800 600">
				{layers.map((layer) => (
					<ConfiguratorSvgPreviewLayer
						key={layer.layerId}
						layerId={layer.layerId}
						value={layer.value}
						onReady={readyHandler}
					/>
				))}
			</svg>
		</div>
	)
}

function ConfiguratorPartEditor(props: {
	input: ConfiguratorPartEditability
	handler: ConfiguratorHandler
	preset: ConfiguratorPreset
	info: ConfiguratorInfoContextValue
}) {
	const {
		input,
		handler: [value, actions],
	} = props

	const { inputId, input: inputType } = input

	const getLightMaterialLabel = (value: string) => {
		return (
			(
				<span className={s.ColorLabel}>
					<span
						className={s.ColorLabelInner}
						style={{
							backgroundColor: props.info.lightMaterials[value].colorHex,
						}}></span>
				</span>
			) ?? value
		)
	}

	const setValue = useCallback(
		(_type: ConfiguratorInputType, id: InputId, value: string) => {
			actions.setInputValue(id, value)
		},
		[actions]
	)

	const changeHandler = useCallback<
		React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	>(
		(e) => {
			const text = e.currentTarget.value
			setValue('text', inputId, text)
		},
		[inputId, setValue]
	)

	const inputValue = useMemo(() => {
		return value.inputValues[inputId]
	}, [inputId, value.inputValues])

	switch (inputType) {
		case 'text':
			return (
				<div className={s.TextWrapper}>
					<p>Text:</p>
					<textarea
						className={s.TextArea}
						onChange={changeHandler}
						value={inputValue}
						name={inputId}
					/>
				</div>
			)
		case 'icon':
			return (
				<div>
					<RadioPicker
						title="Ikonka:"
						value={inputValue}
						options={props.preset.icons}
						onChange={(_name, value) => setValue('icon', inputId, value)}
						name={inputId}
					/>
				</div>
			)
		case 'lightMaterial':
			return (
				<div>
					<RadioPicker
						title="Barva:"
						value={inputValue}
						options={props.preset.lightMaterials}
						onChange={(_name, value) => setValue('lightMaterial', inputId, value)}
						name={inputId}
						label={getLightMaterialLabel}
					/>
				</div>
			)
	}

	return null
}
