import { useMemo } from 'react'
import { getConfiguratorPrice } from '../../utils/getConfiguratorPrice'
import type { ConfiguratorInfoContextValue } from '../contember/transformers'
import type { ConfiguratorValueV1 } from '../types'

export function useConfiguratorPriceInfo(
	value: ConfiguratorValueV1,
	context: ConfiguratorInfoContextValue
) {
	const font = context.fonts[value.font]
	const koef = context.canvasSizes[value.canvasSize].priceKoef || 1
	return useMemo(() => {
		const pricePerLetter =
			font && font.localesByLocale?.characterPrice ? font.localesByLocale?.characterPrice : 100
		return getConfiguratorPrice(value, pricePerLetter, koef)
	}, [font, koef, value])
}
